import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"



const artwork_row = (artworks) => {
      let artwork_rows = artworks.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }
    console.log(item);
    resultArray[chunkIndex].push({
    type:"image", 
    link:"/" + item.slug,
    url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.medium,
    details:<div><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
    </div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow images={row} /> )
    
    return artwork_rows;
}
const enquire = (sold) => {
  if(sold && sold.includes("sold")) {
    return <div style={{textAlign:"center", margin:"20px"}}>Sold</div>
  }
  return <ContactForm><div className={"titles inline"}><h3>Enquire about this artwork</h3></div></ContactForm>
}

const moreWorks = (artworks, artist_name) => {
  if(artworks && artworks.length > 0) 
  return <>
  <Break />  

        <Titles titles={{"h2":`More works by ${artist_name} for sale`}} />
        
        {artwork_row(artworks)}
        </>
}

const price = (price,showPrice,sold) => {

  if(showPrice && !sold.[0]) {

    return <ImageRow  images={[{type:"text", text: <div><p style={{padding:"36px 20px 0px 20px", textAlign: "center", fontSize:"18px",   whiteSpace: "pre-wrap" }}>{price}</p></div>, span:"12"}] } />  
  }
}
const IndexPage = ({pageContext}) => {
    if(pageContext.artwork) {

      return (<div>
        <Layout >
          <SEO title="Susan " />
          <div style={{ }}>
            <div style={{textAlign:"center"}} className={"titles inline"}><Link style={{textDecoration:"none", color:"black"}} to={"/" + pageContext.artwork.artist[0].slug}><h3 style={{marginRight:"0px", marginBottom:"20px",marginTop:"100px", textAlign:"center"}}>{pageContext.artwork.artist[0].title}</h3></Link></div>
            <h1 style={{textAlign:"center", fontSize:"36px", marginTop:"0px",marginBottom:"40px"}}>{pageContext.artwork.title}</h1>
              <ImageRow images={[{size:"full", url:pageContext.artwork.images[0].medium, details:<div><div className="title">Adam Gottlieb</div><div className="materials">Lunar Diptych</div><div className="dimensions"><span>{pageContext.artwork.artist[0].title}</span><span>125cm x 55m x 55cm</span>
            </div></div>
        }]} />
      <ImageRow  images={[{type:"text", text: <div><p style={{padding:"36px 20px 0px 20px", textAlign: "center", fontSize:"18px",   whiteSpace: "pre-wrap" }}>{pageContext.artwork.materials}</p></div>, span:"12"}] } />
  
   {price(pageContext.artwork.price,pageContext.artwork.showPrice?.[0],pageContext.artwork.sold)}
   {enquire(pageContext.artwork.sold)}
        <ImageRow  images={[{type:"text", text: <div><p style={{padding:"36px 20px 0px 20px", textAlign: "center", fontSize:"18px",   whiteSpace: "pre-wrap" }}>{pageContext.artwork.pageText}</p></div>, span:"12"}] } />      


        {moreWorks(pageContext.artwork.artist[0].artworks, pageContext.artwork.artist[0].title)}
          </div>

        </Layout>


      </div>
      )
      
}
return <div></div>
}

export default IndexPage
